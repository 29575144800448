import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import { Link } from 'gatsby'
import Video from "components/video"
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const StayAheadOfYourOralHealthIn2022Page: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const oral_health = data.oral_health?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Stay Ahead Of Your Oral Health In 2022</title>
      <meta name="description" content="Don't neglect your dental health and oral health. If you were negligent in this area last year, now is the ideal opportunity to visit a dentist in McCordsville." />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta property="og:description" content="Don't neglect your dental health and oral health. If you were negligent in this area last year, now is the ideal opportunity to visit a dentist in McCordsville." />
      <meta property="og:url" content="https://thedentistsatgc.com/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="Don't neglect your dental health and oral health. If you were negligent in this area last year, now is the ideal opportunity to visit a dentist in McCordsville." />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <section id="blog-post-section">
          <Container>
            <div className="col-lg-12">
              <h1>
                Dental Visit
              </h1>
              <ul className="list-inline">
                <li className="list-inline-item article-time">
                  2022/01/04
                </li>
                <li className="list-inline-item badge badge-primary">
                  Dental Visit
                </li>
                <li className="list-inline-item badge badge-secondary">
                  Stay Ahead Of Your Oral Health In 2022
                </li>
              </ul>
                <Img fluid={oral_health} className="blog-post-img"/>
              <p>
                The start of a new year is an excellent time to make improvements in your life and set new goals that you should carry with you for your life. One thing that you shouldn't forget is your dental health. 
              </p>
              <p>
                If you were negligent in this area last year, now is the ideal opportunity to visit a dentist in McCordsville, IN. The last couple of years has had a traumatic impact on many individuals for a variety of reasons. If that interrupted your regular visit to the dentist or resulted in other damage to your teeth, now is the time to get that fixed. 
              </p>
              <p>
               Continue reading to learn why you should take action.
              </p>
              <p>
                One of the reasons why the new year is such a happy time for many individuals is that it allows them to make positive changes in their lives. You should think about your dental health when looking to improve anything. If things didn't go well in this area last year, there's no better time than right now to start over. It's not only about getting better; it's also about avoiding any possible suffering and complicated forms of care in coming years as well. 
              </p>
              <p>
               Overlooking oral hygiene and regular maintenance of your dental health can be extremely detrimental to your teeth and gums. 
              </p>
              <p>
               Maintenance goes beyond just brushing and flossing your teeth to maintain good oral health. It is also a component of your overall health. 
              </p>
              <p>
                It affects the body in much the same manner as other diseases, moving from one area of the body to another and influencing a person's general health in the following ways:
              </p>
              <p>
                Gum disease activates an immune response. A chronic immune response weakens the body if it is not dealt with. Your immune system is made to react over short period of time to fight off problems in your body so you can return to a normal state.
              </p>
              <p>
               Additionally, chronic inflammation is considered to be a contributing factor in heart disease and other types of illness. Research from a study at the University of Copenhagen showed that there was a link between gum disease and diabetes. 
              </p>
              <p>
                It is important to remember that small cavities can turn into large cavities that require more work to restore, resulting in more pain and higher costs. The best thing to do is just to stay on top of your oral hygiene and dental visits to avoid that. 
              </p>
              <p>
                Committing to better oral hygiene and regular dental check ups can help you in other ways as well. When you get to the dental office, ask the dentist for any tips on oral hygiene and other ways to improve your oral health. If you have specific concerns or needs, the dentist will be able to give you some great advice and tips.
              </p>
              <p>
               Many people do not ask all the questions they may have or even avoid the dentist altogether for fear of judgment. Don't let that be an obstacle. Get your dental visit with The Dentists At Gateway Crossing and we promise to show you gentle, kind, and caring dentistry in the McCordsville, IN area.
              </p>
              <p>
                Dental insurance is available to millions of Americans, so the majority of them have coverage. Unfortunately, many of them do not make the most of it. This doesn't have to be you. The greatest way to safeguard your monthly expenditure is to visit a dentist for preventive care every six months. What's vital is that you make the most of this opportunity right now.
              </p>
              <p>
               We can make 2022 great. We hope to see you soon at our practice. 
              </p>
              <p>
               Call us today to schedule an appointment.
              </p>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default StayAheadOfYourOralHealthIn2022Page

export const query = graphql`
  query StayAheadOfYourOralHealthIn2022PageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    oral_health: file(name: { eq: "Oral-health-in-2022" }) {
      childImageSharp {
        fluid(maxWidth: 1130, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
